.bluebook-player
  :global(
    .bluebook-player-inner-container
      .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option
      .lrn-label
  ) {
  @apply border border-bluebook-gray1 rounded-2xl mt-[15px] py-[12px] pr-[24px] pl-[56px] leading-[1.5em] min-h-0;
}

.bluebook-player
  :global(
    .lrn.cb-learnosity-player
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option
      .lrn-label::after
  ) {
  @apply border-2 border-bluebook-gray1 w-[25px] h-[25px] font-semibold top-[9px] left-[10px];
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option
      input[type='checkbox']
      + .lrn-label::after
  ) {
  @apply content-[''] w-[20px] h-[20px] border rounded-[2px] top-[12px] left-[13px];
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected
      input[type='checkbox']
      + .lrn-label::after
  ) {
  @apply top-[10px] left-[11px];
}

.bluebook-player
  :global(
    .lrn .lrn_widget.lrn_mcq .lrn_response .lrn_mcqgroup .lrn-mcq-option
  ) {
  @apply !mb-0;
}

.bluebook-player
  :global(.lrn .lrn_widget.lrn_mcq .lrn_mcqgroup .lrn-mcq-option.lrn_selected) {
  @apply bg-white;
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected
      .lrn-label
  ) {
  @apply border-[3px] border-bluebook-blue py-[10px] pr-[22px] pl-[54px];
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected
      input[type='checkbox']
      + .lrn-label::after
  ) {
  @apply pt-[5px] !border-bluebook-blue;
  content: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="%23ffffff" version="1.1" width="14px" height="14px" viewBox="0 0 78.369 78.369" xml:space="preserve"%3E%3Cg%3E%3Cpath d="M78.049,19.015L29.458,67.606c-0.428,0.428-1.121,0.428-1.548,0L0.32,40.015c-0.427-0.426-0.427-1.119,0-1.547l6.704-6.704 c0.428-0.427,1.121-0.427,1.548,0l20.113,20.112l41.113-41.113c0.429-0.427,1.12-%0A.0.427,1.548,0l6.703,6.704 C78.477,17.894,78.477,18.586,78.049,19.015z"/%3E%3C/g%3E%3C/svg%3E') !important;
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected
      .lrn-label::after
  ) {
  @apply bg-bluebook-blue text-white left-[8px] top-[7px];
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected.lrn_incorrect
      .lrn-label::before
  ) {
  color: #dd002f;
  content: '';
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option.lrn_selected.lrn_correct
      .lrn-label::before
  ) {
  color: #4a700a;
  content: '';
}

.bluebook-player
  :global(
    .lrn
      .lrn_widget.lrn_mcq
      .lrn_response
      .lrn_mcqgroup
      .lrn-mcq-option
      .lrn-input:focus
      + .lrn-label
  ) {
  @apply shadow-none outline-4;
  outline-style: auto;
}
